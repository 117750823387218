import Vue from "vue";
import Router from "vue-router";
import axios from "axios";
var consts = require("./config");
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/wait",
      name: "waiting",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/status/:_id/:_secu",
      name: "Status",
      component: () => import("./views/Status.vue"),
    },
    {
      path: "/finish",
      name: "finish",
      component: () => import("./views/Finish.vue"),
    },
  ],
});

export default router;

/*GET FORM DATA INFOS */
function getData() {
  return axios.get("https://api.lucien.ai/api/v2/formdata/" + consts.GAME_ID);
}

router.beforeEach(async (to, from, next) => {
  /*GET FORM DATA INFOS */
  let { data } = await getData();

  if (data.mentionsLegales) window.mentions = data.mentionsLegales;

  /* SET GOOGLE ANALYTICS */
  if (data.tracking && !window.analyticsInitialized) {
    console.log("About to init GA");
    window.analyticsInitialized = true;
    if (to.name == undefined) {
      var name = "home";
    } else {
      var name = to.name;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("config", data.tracking, {
      send_page_view: false,
    });
    gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
    });
    gtag("event", "page_view", {
      page_title: name,
      page_location: window.location.href,
      page_path: to.path,
    });
  }
  if (data.tracking && localStorage.cookies) {
    if (window.gtag) {
      gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    }
  }

  /* CHECK ROUTES */
  var uri = window.location.href;
  var staging_heroku = uri.match(/heroku/g);
  var staging_netlify = uri.match(/netlify/g); // CHECK NETLIFY SD
  var local = uri.match(/localhost/g); // CHECK LOCALHOST

  if (local == null && staging_netlify == null && staging_heroku == null) {
    console.log("PROD");

    /* FORCE HTTPS */
    if (location.protocol !== "https:") {
      window.location =
        "https://" +
        window.location.hostname +
        window.location.pathname +
        window.location.search;
    }

    /* CALCUL DE LA PERIODE */
    var dateBegin = new Date(data.date_debut);
    var dateOff = new Date(data.date_fin);
    var today = new Date();

    console.log(to.name);

    if (
      dateOff > today &&
      dateBegin > today &&
      to.path !== "/wait" &&
      to.name !== "Upload" &&
      to.name !== "Questionnaire" &&
      to.name !== "Status"
    ) {
      // OP EN ATTENTE
      return next("/wait");
    } else if (
      dateOff < today &&
      to.path !== "/finish" &&
      to.name !== "Upload" &&
      to.name !== "Questionnaire" &&
      to.name !== "Status"
    ) {
      // OP TERMINEE
      return next("/finish");
    } else {
      // OP ENCOURS
      next();
    }
  } else {
    // URL DE DEV
    console.log("DEV");
    next();
  }
});
